import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import VueLazyLoad from 'vue3-lazyload'
import '@fortawesome/fontawesome-free/css/all.css';
import router from './router.js';
import axios from 'axios';
import VueSilentbox from 'vue-silentbox'

const app = createApp(App)
app.config.globalProperties.$http = axios.create({
    baseURL: 'https://teichboys.de/api/',
    timeout: 10000,
});
app.use(router)
app.use(VueLazyLoad, {
    loading: '',
    error: ''
})
app.use(VueSilentbox)
app.mount('#app')
