<template>
  <div class="grid-item w-1/2 md:w-1/3 overflow-hidden px-2 md:px-4 relative"
       @click="handleClick">
    <img v-lazy="{src: item.src, lifecycle: {loaded: (el) => emit('image-loaded', el)}}" :alt="item.alt"
         class="w-full h-auto object-cover rounded-none shadow-lg mb-4 md:mb-8"
         loading="lazy"/>
    <div v-if="item.video" class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
      <i class="fa fa-play-circle fa-3x text-white" aria-hidden="true"></i>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  item: Object,
  index: Number
});

const emit = defineEmits(['open-media']);

const handleClick = () => {
  emit('open-media', props.item, props.index);
};
</script>

<style scoped>
.fa-play-circle {
  color: rgba(255, 255, 255, 0.75);
}
</style>
