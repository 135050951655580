<template>
  <div class="text-center mb-6">
    <h2 class="text-2xl font-bold text-[#0080ff] mb-2">Login</h2>
    <div v-if="loginMessage" :class="{'bg-green-500': loginSuccess, 'bg-red-500': !loginSuccess}" class="mb-4 p-4 text-white text-center">
      {{ loginMessage }}
    </div>
    <form @submit.prevent="submitLogin" class="relative w-10/12 lg:w-1/2 mx-auto space-y-4">
      <div>
        <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Passwort *</label>
        <input type="password" id="password" v-model="form.password"
               class="appearance-none border rounded-none w-full py-2 px-3 text-gray-700" required>
      </div>
      <button type="submit" class="bg-[#0080ff] hover:bg-blue-700 text-white font-bold py-2 px-4" :disabled="isLoading">
        Einloggen
      </button>

      <div v-if="isLoading"
           class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-75">
        <div class="loader"></div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      form: {
        password: '',
      },
      isLoading: false,
      loginMessage: '',
      loginSuccess: false,
    }
  },
  methods: {
    submitLogin() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.loginMessage = '';
      this.loginSuccess = false;

      const formData = new FormData();
      formData.append('action', 'login'); // Aktion hinzufügen
      formData.append('passwort', this.form.password); // Name des Feldes wie im PHP-Backend erwartet

      fetch('/api/auth.php', { // Pfad aktualisiert
        method: 'POST',
        body: formData
      })
          .then(response => {
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return response.text();
          })
          .then(data => {
            this.isLoading = false;
            // Entscheidung basierend auf der Antwort, da Ihr PHP-Skript unterschiedliche Nachrichten sendet
            if (data === 'Login erfolgreich') {
              this.loginMessage = data;
              this.loginSuccess = true;
              this.$router.push('/admin');
            } else {
              throw new Error(data); // Login fehlgeschlagen
            }
          })
          .catch(error => {
            this.isLoading = false;
            this.loginSuccess = false;
            this.loginMessage = "Login fehlgeschlagen: " + error.toString();
          });
    }
  }
}
</script>

<style scoped>
/* Hier können Sie den Loader und andere spezifische Stile definieren, wie im Kontaktformular */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #0080ff;
  /* Rest des Loader-Stils */
}
</style>
