<template>
  <div class="mb-4 md:mb-8 bg-blue-600 text-white p-6 md:p-12">
    <h2 class="text-3xl md:text-4xl font-bold mb-4">Willkommen bei den TeichBoys – Tanz und Tradition aus Much-Kreuzkapelle!</h2>
    <p class="mb-4">
      Wir sind die TeichBoys, ein charismatisches Männerballett, das 2008 ins Leben gerufen wurde, um den tanzbegeisterten Töchtern der TeichGirls nachzueifern. Mit Tänzern im Alter von Anfang Vierzig bis Anfang Sechzig bringen wir eine einzigartige Mischung aus Erfahrung und Energie auf jede Bühne.
    </p>
    <h3 class="text-2xl font-semibold mb-3">Was wir bieten:</h3>
    <ul class="list-disc pl-5 mb-4 text-white">
      <li>Vielfältige Auftritte: Von Karnevalsveranstaltungen über Erntefeste bis hin zu Familienfeiern – wir sorgen für Unterhaltung.</li>
      <li>Leidenschaftlicher Tanz: Jedes Jahr kreieren wir einen neuen Tanz, den wir mit Hingabe einstudieren und präsentieren.</li>
      <li>Auszeichnungen: Stolz auf unsere Erfolge, wie den 3. Platz beim Sauerländer Turnier der Strammen Waden und Auftritte auf nationaler Ebene.</li>
    </ul>
    <p>
      Entdecken Sie mehr über unsere Auftritte und erfahren Sie, wie Sie uns für Ihr nächstes Event buchen können.
    </p>
  </div>
</template>

<script>
export default {
  name: 'IntroText'
}
</script>
