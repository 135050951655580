<template>
  <div>
    <Header />
    <IntroText />

    <div class="mb-4 md:mb-8 text-center">
      <h2 class="text-2xl font-bold text-blue-600 mb-2">Unsere Galerie</h2>
      <p>Erlebe die Highlights und unvergesslichen Momente unserer Tanzveranstaltungen. Jedes Bild erzählt eine eigene
        Geschichte aus unserer leidenschaftlichen Tanzwelt.</p>
    </div>
    <GalleryGrid />

    <ContactForm />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import IntroText from '../components/IntroText.vue';
import ContactForm from '../components/ContactForm.vue';
import Footer from '../components/Footer.vue';
import GalleryGrid from "../components/GalleryGrid.vue";

export default {
  name: 'App',
  components: {
    GalleryGrid,
    Header,
    IntroText,
    ContactForm,
    Footer
  }
}
</script>
