<template>
  <div ref="masonryContainer" class="grid grid-cols-2 md:grid-cols-3 m-2 md:m-4">
    <GalleryItem v-for="(item, index) in images" :key="index" :item="item" :index="index"
                 @image-loaded="handleImageLoad"
                 @open-media="openLightbox(item, index)"/>
  </div>

  <silent-box ref="silentbox" :gallery="preparedMedia">
  </silent-box>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import GalleryItem from './GalleryItem.vue';
import {useGalleryStore} from '../stores/galleryStore';
import Masonry from 'masonry-layout';
import 'vue-silentbox/dist/style.css';

const {images, fetchImages} = useGalleryStore();
const masonryContainer = ref(null);
let masonry
const silentbox = ref(null);

const openLightbox = (item, index) => {
  silentbox.value.openOverlay(item, index)
};

onMounted(async () => {
  await fetchImages()
      .then(() => {
        masonry = new Masonry(masonryContainer.value, {
          itemSelector: '.grid-item',
          columnWidth: '.grid-item',
          percentPosition: true
        });
      })
});

const preparedMedia = computed(() => {
  return images.value
      .sort((a, b) => a.order - b.order)
      .map(item => {
        const mediaItem = {
          src: item.src,
          alt: item.alt,
          description: item.alt
        };

        if (item.video) {
          return {...mediaItem, src: mediaItem.video, type: 'video', controls: true, autoplay: false};
        }

        return mediaItem;
      });
});

const handleImageLoad = (el) => {
  if (masonry) {
    masonry.layout();
  }
};
</script>

<style>
.silentbox-item {
  display: none !important;
}
</style>