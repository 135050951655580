<template>
  <div>
    <h2>Dateien hochladen</h2>
    <input type="file" @change="handleFileChange" multiple>
    <button @click="uploadFiles">Hochladen</button>
    <div v-if="uploadStatus.length">
      <p v-for="(status, index) in uploadStatus" :key="index">{{ status }}</p>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import axios from 'axios';

const selectedFiles = ref([]);
const uploadStatus = ref([]);

const handleFileChange = (event) => {
  selectedFiles.value = event.target.files;
  uploadStatus.value = []; // Reset upload status messages
};

const uploadFiles = async () => {
  const formData = new FormData();

  for (let file of selectedFiles.value) {
    formData.append('files[]', file);
  }

  try {
    const response = await axios.post('/api/upload.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    uploadStatus.value.push('Upload erfolgreich: ' + response.data.message);
  } catch (error) {
    uploadStatus.value.push('Fehler beim Upload: ' + error.message);
  }
};
</script>
