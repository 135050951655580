// store/galleryStore.js
import { reactive, toRefs, inject } from 'vue'
import axios from 'axios'

const state = reactive({
    images: [],
    showLightbox: false,
    activeImageIndex: 0
})

export const useGalleryStore = () => {
    const fetchImages = async () => {
        try {
            const response = await axios.get('/api/list_images.php')
            state.images = response.data
        } catch (error) {
            console.error('Error fetching images:', error)
        }
    }

    const openLightbox = (index) => {
        state.activeImageIndex = index
        state.showLightbox = true
    }

    const closeLightbox = () => {
        state.showLightbox = false
    }

    return { ...toRefs(state), fetchImages, openLightbox, closeLightbox }
}
