<template>
  <div class="header-bg flex flex-col justify-evenly items-center h-screen text-center text-white">
    <div class="mb-16 w-full h-1/2">
      <img src="../assets/teichboys.svg" class="logo mb-2 w-10/12 mx-auto xl:w-8/12" alt="Logo">
      <div class="social-media-icons text-white text-3xl lg:text-4xl">
        <a href="https://www.facebook.com/teichboys/" class="mx-4 lg:mx-8 hover:brightness-75 transition-transform"><i class="fab fa-facebook-f"></i></a>
        <a href="https://www.instagram.com/teichboys/" class="mx-4 lg:mx-8 hover:brightness-75 transition-transform"><i class="fab fa-instagram"></i></a>
        <!-- Füge hier weitere Social Media-Icons hinzu -->
      </div>
    </div>
    <div>
      <a href="#kontakt" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">Kontaktiere
        uns</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style>
.header-bg {
  background-image: url('../assets/backdrop.jpg');
  background-size: cover;
  background-position: center;
}
</style>
