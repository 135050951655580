<template>
  <footer class="bg-[#0080ff] text-white py-6 mt-12">
    <div class="container mx-auto text-center">
      <p>&copy; {{ new Date().getFullYear() }} Teichboys. Alle Rechte vorbehalten.</p>
      <nav class="mt-4">
        <ul class="flex justify-center space-x-4">
          <li><a href="/#datenschutz" class="hover:underline">Datenschutz</a></li>
          <li><a href="/#impressum" class="hover:underline">Impressum</a></li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
/* Optional: Weitere Styles */
</style>
