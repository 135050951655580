<template>
  <div class="container mx-auto p-6">
    <h1 class="text-2xl font-bold mb-4">Bildverwaltung</h1>
    <button @click="logout">Logout</button>

    <UploadMedia></UploadMedia>


    <!-- Textfelder für die manuelle Sortierung -->
    <div class="mb-4">
      <ul>
        <li v-for="image in imagesSorted" :key="image.id">
          <div class="flex items-center space-x-4 mb-2">
            <div class="max-w-sm rounded overflow-hidden shadow-lg">
              <img :src="image.src" :alt="image.alt" class="w-full">
              <input type="number" v-model.number="image.order" class="text-input" min="1">
              <span>{{ image.name }}</span>
              <div class="px-6 py-4">
                <button @click="deleteImage(image.src)"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Löschen
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <button @click="saveNewOrder" class="save-order-button">Sortierung speichern</button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import UploadMedia from "../components/UploadMedia.vue";

export default {
  components: {
    UploadMedia,
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "gallery",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    imagesSorted() {
      return this.images.sort((a, b) => a.order - b.order);
    },
  },
  data() {
    return {
      selectedFiles: [],
      images: [],
      cachedOrder: [],
      previewUrls: new Map(),
      drag: false
    };
  },
  methods: {
    logout() {
      const formData = new FormData();
      formData.append('action', 'logout'); // Aktion für das Backend

      fetch('/api/auth.php', { // Pfad zu Ihrem auth.php Skript
        method: 'POST',
        body: formData,
        credentials: 'include' // Stellen Sie sicher, dass Cookies mitgesendet werden, falls nötig
      })
          .then(response => {
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return response.text();
          })
          .then(data => {
            if (data === 'Logout erfolgreich') {
              // Hier könnten Sie den Nutzer zur Startseite oder zur Login-Seite umleiten
              this.$router.push('/'); // Weiterleitung zur Login-Seite
            } else {
              console.error('Logout fehlgeschlagen:', data);
            }
          })
          .catch(error => {
            console.error('Fehler beim Ausloggen:', error);
          });
    },
    handleFileChange(e) {
      this.selectedFiles = Array.from(e.target.files).map(file => ({
        file: file,
        preview: URL.createObjectURL(file),
        loading: false,
        message: null,
        success: false
      }));
    },
    removeFile(index) {
      const file = this.selectedFiles[index];
      if (file) {
        URL.revokeObjectURL(this.selectedFiles[index].preview);
        this.previewUrls.delete(file);
        this.selectedFiles.splice(index, 1);
      }
    },
    saveNewOrder() {
      // Senden der aktualisierten Bilderliste an das Backend zum Speichern
      const orderedImages = this.imagesSorted.map((image, index) => ({
        src: image.src,
        alt: image.alt,
        order: index + 1
      }));

      fetch('https://teichboys.de/api/update_order.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderedImages)
      })
          .then(response => {
            if (!response.ok) {
              throw new Error('Fehler beim Aktualisieren der Reihenfolge');
            }
            return response.json();
          })
          .then(data => {
            console.log(data.message);
            // Optional: Bestätigungsnachricht anzeigen oder weitere Aktionen
          })
          .catch(error => {
            console.error(error);
            // Zurücksetzen auf die ursprüngliche Reihenfolge
            this.updateImagesOrderToMatchCachedOrder()
          });
    },
    updateImagesOrderToMatchCachedOrder() {
      // Entfernen von Elementen, die nicht in cachedOrder sind
      for (let i = this.images.length - 1; i >= 0; i--) {
        if (!this.cachedOrder.some(cachedImage => cachedImage.src === this.images[i].src)) {
          this.images.splice(i, 1);
        }
      }

      // Hinzufügen von Elementen, die in cachedOrder, aber nicht in images sind
      this.cachedOrder.forEach(cachedImage => {
        if (!this.images.some(image => image.src === cachedImage.src)) {
          this.images.push(cachedImage);
        }
      });

      // Sortieren der images entsprechend der cachedOrder
      this.images.sort((a, b) => {
        const indexA = this.cachedOrder.findIndex(img => img.src === a.src);
        const indexB = this.cachedOrder.findIndex(img => img.src === b.src);
        return indexA - indexB;
      });

      // Aktualisieren der 'order'-Eigenschaft jedes Bildes in 'this.images'
      this.images.forEach((img, index) => {
        img.order = this.images.length - index;
      });
    },
    uploadImages() {
      this.selectedFiles.forEach((fileInfo, index) => {
        console.log(this.selectedFiles[index])
        const formData = new FormData();
        formData.append('image', fileInfo.file);

        this.selectedFiles[index].loading = true; // Setze Lade-Status

        fetch('https://teichboys.de/api/upload.php', {
          method: 'POST',
          body: formData
        })
            .then(response => {
              if (!response.ok) {
                throw new Error('Upload fehlgeschlagen');
              }
              return response.json();
            })
            .then(data => {
              this.fetchImages(); // Bilderliste neu laden
              this.selectedFiles[index].loading = false;
              this.selectedFiles[index].message = 'Upload erfolgreich';
              this.selectedFiles[index].success = true;
              setTimeout(() => {
                this.removeFile(index);
              }, 5000); // Nachricht nach 5 Sekunden entfernen
            })
            .catch(error => {
              this.selectedFiles[index].loading = false;
              this.selectedFiles[index].message = error.message;
              this.selectedFiles[index].success = false;
              console.error('Error:', error)
            })
      });
    },
    deleteImage(imagePath) {
      fetch('https://teichboys.de/api/delete_image.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({imagePath})
      })
          .then(response => response.json())
          .then(data => {
            console.log(data);
            if (data.message) {
              // Bild aus der Liste entfernen oder Liste neu laden
              let index = this.images.indexOf(this.images.find((value) => { return value.src === imagePath }))
              this.images = this.images.filter((e, i)  => i !== index)
              this.fetchImages();
            }
          })
          .catch(error => console.error('Error:', error));
    },
    fetchImages() {
      fetch('https://teichboys.de/api/list_images.php')
          .then(response => response.json())
          .then(data => {
            this.cachedOrder = data;
            this.updateImagesOrderToMatchCachedOrder()
          });
    }
  },
  mounted() {
    this.fetchImages();
  }
}
</script>

<style>
.sorting-grid-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
