import { createRouter, createWebHistory } from 'vue-router';
import Home from './pages/Home.vue';
import Admin from './pages/Admin.vue';
import Login from './pages/Login.vue';

async function isAuthenticated() {
    try {
        const response = await fetch('/api/check_auth.php');
        if (!response.ok) throw new Error('Netzwerkantwort war nicht ok.');
        const data = await response.json();
        return data.authenticated;
    } catch (error) {
        console.error('Authentifizierungsprüfung fehlgeschlagen', error);
        return false;
    }
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        beforeEnter: async (to, from, next) => {
            const auth = await isAuthenticated();
            if (!auth) {
                next({ name: 'Login' });
            } else {
                next();
            }
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
