<template>
  <div id="kontakt" class="text-center mb-6">
    <h2 class="text-2xl font-bold text-[#0080ff] mb-2">Du willst, dass wir auch bei dir tanzen?</h2>
    <p>Kontaktiere uns gerne telefonisch unter den folgenden Nummern:</p>
    <p><span class="font-bold">0171 2664840</span> oder <span class="font-bold">0172 5704616</span></p>
    <p>Alternativ stehen wir dir auch per E-Mail zur Verfügung:</p>
    <p class="font-bold">info@teichboys.de</p>
    <p class="mt-4">Für eine bequeme Kontaktaufnahme kannst du auch unser Kontaktformular nutzen.</p>
  </div>
  <div class="relative w-10/12 lg:w-1/2 mx-auto">
    <div v-if="message" :class="{'bg-green-500': !isError, 'bg-red-500': isError}" class="mb-4 p-4 text-white text-center">
      {{ message }}
    </div>
    <form @submit.prevent="submitForm" class="space-y-4">
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
        <input type="text" id="name" v-model="form.name"
               class="appearance-none border rounded-none w-full py-2 px-3 text-gray-700" required>
      </div>
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2" for="email">E-Mail *</label>
        <input type="email" id="email" v-model="form.email"
               class="appearance-none border rounded-none w-full py-2 px-3 text-gray-700" required>
      </div>
      <div>
        <label class="block text-gray-700 text-sm font-bold mb-2" for="message">Nachricht *</label>
        <textarea id="message" v-model="form.message"
                  class="appearance-none border rounded-none w-full py-2 px-3 text-gray-700 min-h-16" rows="4"
                  required></textarea>
      </div>
      <button type="submit" class="bg-[#0080ff] hover:bg-blue-700 text-white font-bold py-2 px-4" :disabled="isLoading">
        Senden
      </button>
    </form>

    <div v-if="isLoading"
         class="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-75">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      isLoading: false,
      message: '',
      isError: false
    }
  },
  methods: {
    submitForm() {
      if (this.isLoading)
        return
      this.isLoading = true;
      this.message = '';
      this.isError = false;

      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      formData.append('message', this.form.message);

      fetch('/api/contact.php', {
        method: 'POST',
        body: formData
      })
          .then(response => {
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return response.text();
          })
          .then(data => {
            this.isLoading = false;
            this.message = data;
          })
          .catch(error => {
            this.isLoading = false;
            this.isError = true;
            this.message = error.toString();
          });
    }
  }
}
</script>

<style>
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}

.loader {
  color: #0080ff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  width: 10em;
  height: 10em;
  border-top: 1.1em solid rgba(0, 128, 255, 0.2);
  border-right: 1.1em solid rgba(0, 128, 255, 0.2);
  border-bottom: 1.1em solid rgba(0, 128, 255, 0.2);
  border-left: 1.1em solid #0080ff;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
